<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-11">Reklam Durum Raporu</div>
            <div class="col-1">
            </div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="3">
              <CInput
                description="Başlangıç Tarihi"
                type="date"
                v-model="startDate"
                horizontal
              />
            </CCol>
            <CCol col="3">
              <CInput
                description="Bitiş Tarihi"
                type="date"
                v-model="endDate"
                horizontal
              />
            </CCol>
            <CCol col="3">
              <CSelect
                description="Reklam Şirketi"
                placeholder="Hepsi"
                :options="activeAdCamps"
                type="text"
                horizontal
                :value.sync="adcampId"
              />
            </CCol>
            <CCol col="3">
              <CInput
                description="Click Ücreti"
                placeholder="0"
                horizontal
                type="number"
                v-model="costPerClick"
                step="0.25"/>
            </CCol>
          </CRow>
          <CRow>
            <CCol col="3">
              <CInput
                description="Başlama Saati"
                type="time"
                v-model="startTime"
                horizontal
              />
            </CCol>
            <CCol col="3">
              <CInput
                description="Bitiş Saati"
                type="time"
                v-model="endTime"
                horizontal
              />
            </CCol>
            <CCol col="3">
              <CSelect
                description="Sipariş Durumu"
                placeholder="Hepsi"
                :options="orderStatusList"
                type="text"
                horizontal
                :value.sync="orderStatus"
              />
            </CCol>
            <CCol col="3">
              <CButton color="success" @click="filterAdCamp(startDate, endDate, adcampId,costPerClick,orderStatus)">
                Filtre Uygula
              </CButton>&nbsp;&nbsp;
            </CCol>
          </CRow>
          <CCol col="12">
            <CDataTable
              :items="adCamps"
              :fields="fields"
              :loading="loading"
              :items-per-page="10"
              pagination
              clickable-rows
              hover
              v-on:refresh="filterAdCamp(startDate, endDate, adcampId,costPerClick,orderStatus)"
            />
          </CCol>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import moment from "moment";

export default {
  name: 'AdCamp',
  data() {
    return {
      fields: [
        {key: 'adcamp_id', label: 'ID', _style: 'font-size:12px'},
        {key: 'alternative_ids', label: 'Alternatives', _style: 'font-size:12px'},
        {key: 'click', label: 'Click', _style: 'font-size:12px'},
        {key: 'orderCount', label: 'Sipariş Sayısı', _style: 'font-size:12px'},
        {key: 'conversion', label: 'Geri Dönüşüm', _style: 'font-size:12px'},
        {key: 'conversionRate', label: 'Geri Dön. Oranı', _style: 'font-size:12px'},
        {key: 'cost', label: 'Maliyet', _style: 'font-size:12px'}
      ],
      adCampData: {
        adCamp: [],
        count: 0
      },
      orderStatusList: [
        {"value": "waiting_for_payment", "label": "WAITING FOR PAYMENT"},
        {"value": "preparing", "label": "PREPARING"},
        {"value": "canceled", "label": "CANCELED"},
        {"value": "shipping", "label": "SHIPPING"},
        {"value": "tracking", "label": "TRACKING"},
        {"value": "delivered", "label": "DELIVERED"},
        {"value": "returned", "label": "RETURNED"}],
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
      adcampCompanyList: [],
      adcampCompanyNameList: [],
      adcampId: "",
      costPerClick: "",
      orderStatus: ""
    }
  },
  computed: {
    adCamps: function(){
      return this.$store.getters.adCamps
    },
    loading: function(){
      return this.$store.getters.reportLoading
    },
    activeAdCamps: function(){
      let data = []
      this.$store.getters.activeAdCamps.map(camps => data.push({ value: camps.id, label: camps.name }))
      return data
    }
  },
  methods: {
    async filterAdCamp(startDate, endDate, adcampId, costPerClick, orderStatus) {
      let params = {
        "startDate": moment(startDate).format('YYYY-MM-DD'),
        "endDate": moment(endDate).format('YYYY-MM-DD'),
        "adcampId": adcampId,
        "costPerClick": costPerClick,
        "orderStatus": orderStatus
      };
      await this.$store.dispatch('adCamp_list', params)
    },
  },
  created() {
    var today = new Date();
    this.endDate = moment(today).format('YYYY-MM-DD');
    this.startDate = moment().subtract(1, "month").format('YYYY-MM-DD');
    this.startTime = "00:00";
    this.endTime = "00:00";
    this.$store.dispatch('activeAdCamp_list')
  }
}
</script>
